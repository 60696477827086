<template>
    <div>
        <a-form-model ref="siteForm" :model="site" :rules="rules">
                <a-form-model-item label="Your Site Title" prop="title" help="This will be the title that google search result will show or on the browser.">
                    <a-input
                        v-model="site.title"
                        placeholder="Enter Site Title"
                        size="large"
                    />
                </a-form-model-item>

                <a-form-model-item label="Dark Logo" prop="darkLogo" class="empty mt-4">
                    <ImageBoxSelector v-model="site.darkLogo"/>
                </a-form-model-item>

                <a-form-model-item label="Light Logo" prop="lightLogo" class="empty mt-4" data-hi>
                    <ImageBoxSelector v-model="site.lightLogo"/>
                </a-form-model-item>
            
        </a-form-model>
        
        <portal to="destination">
            <a-button size="large" class="back-button" @click="next" >Next</a-button>
        </portal>
       
    </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
    props:{
        value:Number,
		template:Object
    },
    components:{ImageBoxSelector},
    data:() => ({
        site:{
            title:'',
            darkLogo:'',
            lightLogo:''
        },
        currentStep:0,

        rules: {
            title: [
                { required: true, message: `Please input your site's title`, trigger: 'blur' }
            ],
            darkLogo: [
                { required: true, message: 'Please provide your dark logo' }
            ],
            lightLogo: [
                { required: true, message: 'Please provide your light logo' }
            ],
        },
        onBoarding:false,
    }),
    watch:{
        currentStep(val){
            return this.$emit('input',val)
        }
    },
    computed:{
        instance(){
            return this.$store.state.instance
        },
        theApp(){
            return this.$store.state.theApp
        },
    
    },
    methods:{
        skip(){
            return this.$emit('next')
        },
        next(){
            this.$refs.siteForm.validate(valid => {
                if (valid) {
                    console.log('validddd', valid)
                    return this.$emit('done', this.site)
                    
                } else {
                    console.log('error submit!!');
                }
            });
            
        },

    },
	created(){
		console.log('THIS TEMPLATE', this.template)
		let {_global = {}} = this.template
		if (_global.darkLogo) this.site.darkLogo = _global.darkLogo
		if (_global.lightLogo) this.site.lightLogo = _global.lightLogo
        if (_global.title) this.site.title = _global.title
	}
}
</script>

<style lang="scss">
    .ant-form-item.empty{
        span.ant-form-item-children{

        }
    }
</style>