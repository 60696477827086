<template>
    <div>

        <PreviewTemplate :template="templateToPreview" @close="templateToPreview = ''" />

        <Popup 
            
            v-if="optionStep" 
            @close="closeOption" 
            introImage="https://bildhive.nyc3.digitaloceanspaces.com/apps/contracts/screens.png" 
            color="purple" 
            :step="optionSteps[optionStep - 1]"
            v-model="optionStep"
            title="Headline goes here" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac erat gravida, tempor magna quis, lacinia ex. Pellentesque ac ex aliquam dui feugiat consequat. Morbi id dolor enim. Mauris dictum ligula">

            <template v-slot:right v-if="optionStep">
                <component :is="'OptionStep' + optionStep" @done="nextOptions" :template="newProject" />
            </template>

        </Popup>


        <div class="choose_template_modal" v-if="step === 2">
            <!-- <div class="choose_template_modal-mask"></div> -->

            <div class="choose_template_modal-wrapper">
                
                <div class="choose_template_modal-body">
                    <div class="choose_template_modal-header dF jC">
                        <h3>Pick your theme</h3>
                    </div>

                    <template v-if="templatesTransformed.length">

                        <div class="template-list-wrapper">
                            <template v-for="item in templatesTransformed">
								<template v-if="item.productType && (item.productType == 'all' || item.productType == instance.productType)">
                                	<TemplateCard :item="item" :key="item.id" :selected="item.name === chosenName" @select="chooseTemplate(item)" @preview="previewTemplate(item)"  />
								</template>
							</template>
                        </div>

                    </template>
                    <template v-else>
                        <div class="dF jC aC w-full h-full">
                            <a-icon type="loading" />
                            <div>Loading</div>
                        </div>
                    </template>
                </div>
                <div class="choose_template_modal-footer dF jE py-4 px-8">
                    <a-button type=secondary @click="$emit('close')">CANCEL</a-button>
                    <a-button type=primary class=ml-2 @click="showOptions">CONTINUE</a-button>
                </div>
            </div>
        </div>

        <a-modal title="Create new Site" :visible="step === 1" @cancel="$emit('close')" @ok="showSelectTemplate" >
            <a-form-model :rules="rules" :model="newProject" ref="newSite">
                <a-form-model-item label="Select Project Type" prop="type">
                    <a-select v-model="newProject.type">
                        <a-select-option value="landing">Landing Page</a-select-option>
                        <a-select-option value="microsite">Microsite</a-select-option>
						<a-select-option value="static">Static</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="Project Name" prop="name">
                    <a-input v-model.trim="newProject.name" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>

    </div>
</template>

<script>
import axios from 'axios'
import TemplateCard from '@/components/site/TemplateCard'
import OptionStep1 from '@/components/index/OptionStep1'
import OptionStep2 from '@/components/index/OptionStep2'
import OptionStep3 from '@/components/index/OptionStep3'
import PreviewTemplate from '@/components/site/PreviewTemplate'
import Popup from '@/components/index/OptionPopup'
import {getCookie} from 'bh-mod'

export default {
    components:{
        TemplateCard,
        PreviewTemplate,
        Popup,
        OptionStep1,
        OptionStep2,
        OptionStep3
    },
    props:['start'],
    data:() => ({
        chosenName:'',
        newProject:{
            type:'landing',
            template:'',
            name:'',
            _global:{},
        },
        // optionSteps:[
        //     {
        //         title:`${this.newProject.type == 'landing' ? 'Setting up your landing page title & logos' : 'Setting up your website title & logos'}`,
        //         backButton:true
        //     },
        //     {
        //         title:'Define your colour palette',
        //         backButton:true
        //     },
        //     {
        //         title:'Select your typography',
        //         backButton:true
        //     },
        // ],
        templateToPreview:'',
        templates:[],
        step:0,
        optionStep:0,
        rules:{
            name: [
                { min: 2, message: 'Name should be longer than 2 characters!', trigger: 'blur' },
                { required:true, message: 'Please enter a project name', trigger: 'blur' },
            ],
        },

    }),
    computed:{
        optionSteps(){
            return [{
                title:`${this.newProject.type == 'landing' ? 'Setting up your landing page title & logos' : 'Setting up your website title & logos'}`,
                backButton:true
            },
            {
                title:'Define your colour palette',
                backButton:true
            },
            {
                title:'Select your typography',
                backButton:true
            },]
        },
        templatesTransformed(){
            if (this.templates && this.templates.length){
                let res = this.templates.filter( ({landing = false}) => landing === (this.newProject.type === 'landing') )
                console.log('TEMPLATES TRANSFORED', res)
                if (this.newProject.type == 'landing'){
                    let index = res.findIndex(x => x.landing == true && x.templateSlug == 'blanktemplate');
                    let blank = res[index];
                    res = res.filter(x => x.templateSlug != 'blanktemplate')
                    res = [blank,...res]
                    return res
                } else {
                    let index = res.findIndex(x => x.landing == false && x.templateSlug == 'blanktemplate2');
                    let blank = res[index];
                    res = res.filter(x => x.templateSlug != 'blanktemplate2')
                    res = [blank,...res]
                    return res
                }
            } else {
                return []
            }
        },
		instance(){
			return this.$store.state.instance
		}
    },
    watch:{
        optionStep(val){
            if (val === 0){

            } else {

            }
        },
        step:{
            immediate:true,
            async handler(val){
                if (val === 2){
                    if (!this.templates.length){
                        // let {data} = this.$api.get(`/projects/:instance/?type=web&default=true`)
                        axios.post(`${this.$store.state.appData.siteURL}/list/templates`).then( ({data}) => {
                            this.templates = Object.values(data)
                        })
                    }
                }
            }
        },
        start(val){
            console.log('STARTINGIGNGIGNNGG', val)
            if (!val){
                this.newProject.type = 'landing'
                this.newProject.name = ''
                this.newProject.template = ''
                this.step = 0
            } else {
                this.step = 1
            }
        },
        
    },
    methods:{
        closeOption(){
            this.optionStep = 0
            this.step = 2
        },
        chooseTemplate(item){
            this.chosenName = item.name
			console.log('TEMPLATE", item', item)
            this.newProject.template = item.templateSlug || item.name
            this.newProject._global = item._global || {}
        },
        nextOptions(site){
            
            Object.entries(site).forEach( ([parentKey,parentValue]) => {
                if (typeof parentValue !== 'object') this.newProject._global[parentKey] =  parentValue
                else {

                    if (!this.newProject._global[parentKey]) this.newProject._global[parentKey] = {}

                    Object.entries(parentValue).forEach( ([childKey,childValue]) => {
                        this.newProject._global[parentKey][childKey] = childValue
                    })

                }


            })

            if (this.optionStep < 3) this.optionStep += 1
            else {

                this.$store.commit('LOAD', true)

                let __bhjt = getCookie('__bhjt')

				if (this.instance && this.instance.api && this.instance.api.key){
					this.newProject._global.apiKey = this.instance.api.key
				}

                axios.post(this.$store.state.appData.siteURL + '/create-site/' + this.$store.state.instance.id, {...this.newProject, __bhjt})
                    .then( ({data}) => {
                    
                        this.$api.post('projects/:instance', data)
                            .then( ({data:saved}) => {
                                this.$router.push(`/edit/${saved.id}`)    
                            }).catch(err => {
                                console.error('Someting happend while Saving project')
                            })
                    
                    }).catch( err => {
                        console.error('Someting happend while Creating project')
                        this.$store.commit('LOAD',false)
                    })

            }
        },
        previewTemplate(tmp){
            // this.templateToPreview = tmp.name
            let slug = tmp.templateSlug || tmp.name
            window.open(`https://services.bildhive.${this.$tld}/__set/` + slug)
        },
        showSelectTemplate(){
            this.$refs.newSite.validate( async res => {
                console.log('RESSSSSS',res)
                
                if (res) {
                    this.step = 2
                }
            })
        },
        showOptions(){
            let foundTemplate = this.templates.find(x => x.name === this.chosenName)
            if (!foundTemplate) return this.$message.error('Please select a Template to continue')
            this.step = -1
            this.optionStep = 1
        },
        toNextStep(){

        }
    }

}
</script>

<style lang="scss" scoped>
    .template-list-wrapper{
        display:grid;
        grid-template-columns: repeat(4, minmax(100px,1fr) );
        gap:4ch;

    }
    @media screen and (max-width: 768px) {
        .template-list-wrapper{
            grid-template-columns: repeat(2, minmax(100px,1fr) );
        }
    }
    .choose_template_modal{
        position: absolute;
        left:0;
        top:0;
        .choose_template_modal-wrapper{
            z-index:300;
            position: fixed;
            left:0;
            width:100vw;
            top:0;
            height:100vh;
            background:white;
            display:flex;
            flex-direction: column;
            min-height:0;
        }
        .choose_template_modal-body{
            flex:1;
            overflow: auto;
            min-height:0;
            padding:0 2.5rem;
        }
        .choose_template_modal-header{
            padding:2rem;
            padding-top:4rem;
        }
        .choose_template_modal-footer{
            padding:4rem 2.5rem;
        }
    }

</style>