<template>
    <div class="project-card cursor-pointer" @click="$router.push(`/edit/${project.id}`)">
        <div class="img" :style="`background-image:url(${project.image})`" />
        <div class="project-info-bottom dF p-2 jSB bg-white aC">
                <div>
                    <span>{{project.name}}</span>
                    <small class="block">
                        <template v-if="project.domain && project.url">
                            <a @click.stop="() => {}" target="_blank" :href="`https://${project.url}`"><span class="text-success hover-underline">{{project.url}}</span></a>
                        </template>
                        <span class="text-med-gray" v-else>Not connected</span>
                    </small>
                </div>
            <MoreActions :items="actions" @action="actioned" />
        </div>
    </div>
</template>

<script>
import MoreActions from 'bh-mod/components/common/MoreActions'
export default {
    components:{MoreActions},
    props:['project'],
    data:() => ({
        actions:[
            {
                label:'Preview',
                value:'preview',
                icon:'fe-eye',
            },
            {
                label:'Rename',
                value:'rename',
                icon:'fe-type',
            },
            {
                label:'Edit',
                value:'edit',
                icon:'fe-edit',
            },
            {
                label:'Duplicate',
                value:'duplicate',
                icon:'fe-copy',
            },
			{
                label:'Export to Project',
                value:'export',
                icon:'fe-upload',
            },
            {
                label:'Delete',
                value:'delete',
                icon:'fe-trash',
            },
        ],
    }),
    methods:{
        actioned(e){
            this.$emit(e.key)

        }
    }
}
</script>

<style lang="scss" scoped>
    .project-card{
        box-shadow:0 3px 5px rgba(100,100,100,0.3);
       .img{
           border-bottom:1px solid #dedede;
           height:0;
           width:100%;
           padding-top:57%;
           background-size:cover;
           position: relative;
       }
    }   
</style>