<template>

    <div class="dF fC template_card" :class="selected ? 'template-selected' : ''">
        <template v-if="item.templateSlug != 'blanktemplate' && item.templateSlug != 'blanktemplate2'">
            <picture class="mb-1" @click="$emit('select')">
                <div class="template_card-overlay dF jC aC">
                    <a href="#" class="underline" @click.prevent.stop="$emit('preview')">Preview</a>
                </div>
                <img :src="item.thumbnail" :alt="item.name"/>
            </picture>

            {{name}}
        </template>
        <template v-else>
            <div class="w-full h-full relative" @click="$emit('select')" :style="selected ? 'border: 3px solid var(--primary)' : 'border: 1px dashed rgb(208, 201, 214);'" style="box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;">
                <div class="template_card-overlay dF jC aC">
                    <a href="#" class="underline" @click.prevent.stop="$emit('preview')">Preview</a>
                </div>
                <div class="w-full h-full dF aC jC tC" style="flex-direction: column;">
                    <img src="https://email.bildhive.dev/img/blank.a203e11b.svg" style="object-fit: contain;" />
                    <h6 class="mt-4">Add Blank</h6>
                </div>
            </div>
            {{name}}
        </template>
    </div>
</template>

<script>
import {formatDate} from 'bh-mod'
export default {
    props:['item','selected'],
    computed:{
        name(){
            return this.item.name.trim().replace(/[-_]/g, ' ').split(' ').filter(x => x.trim()).map(x => x[0].toUpperCase() + x.substr(1)).join(' ')
        },



    },
    methods:{
       formatDate
    }
}
</script>

<style lang="scss" scoped>
    a{
        background:var(--primary);
        color:white;
        padding:5px 15px;
        border-radius:4px;
        font-size:14px;
        transition:transform .3s ease-out, box-shadow .3s ease-out;
        box-shadow: 0 3px 10px rgba(100,100,100,0.0);
    }
    a:hover{
        text-decoration: underline;
        text-decoration-color: var(--primary);
        transform:translate3d(0,-.51ch,0);
        box-shadow: 0 3px 5px rgba(100,100,100,0.3);
    }
    .template_card{
        font-size:13px;
        position: relative;
        &.template-selected{
            color:var(--primary);
            picture{
                border:3px solid var(--primary);
            }
        }
        &:hover{
            .template_card-overlay{
                opacity:1;
            }
        }
    }
    .template_card-overlay{
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        background:rgba(255,255,255,0.9);
        opacity:0;
        transition:opacity .3s ease-out;
    }
    picture{
        border:1px solid transparent;
        transition:border-color .3s ease-out;
        position:relative;
        // border:1px solid black;
        height:250px;
        overflow: hidden;
        box-shadow:0 3px 10px rgba(100,100,100,0.3);
        img{
            width:100%;

            object-fit:cover;
        }
    }

</style>
