<template>
    <div>
        <h4 class=mb-3>Colors</h4>

		<!-- <div class="mt-3 mb-5">
				Use Theme Defaults <a-switch size="small" v-model="useThemeDefaults" />
		</div> -->


        <div class="colr-grid" :style="useThemeDefaults ? `opacity:0.5; pointer-events:none;` : ``">
            
            <div class="dF aC" style="gap:2ch;">
                <div class="f1">Primary Color</div>
                <div>
                    <ColorPicker recentText="Chosen Theme" :defaults="chosenTheme" :box-style="true" v-model="site.colors.primaryColor"  :key="newTime"/>
                </div>
            </div>
            
            <div class="dF aC" style="gap:2ch;">
                <div class="f1">Secondary Color</div>
                <div>
                    <ColorPicker :defaults="chosenTheme" :box-style="true" v-model="site.colors.secondaryColor" :key="newTime"/>
                </div>
            </div>

            <div class="dF aC" style="gap:2ch;">
                <div class="f1">Text Color</div>
                <div>
                    <ColorPicker :defaults="chosenTheme" :box-style="true" v-model="site.colors.textColor" :key="newTime"/>
                </div>
            </div>

            <div class="dF aC" style="gap:2ch;">
                <div class="f1">Accent Color</div>
                <div>
                    <ColorPicker :defaults="chosenTheme" :box-style="true" v-model="site.colors.accentColor" :key="newTime"/>
                </div>
            </div>

            <!-- <div class="dF aC" style="gap:2ch;">
                <div class="f1">Button Background Color</div>
                <div>
                    <ColorPicker :defaults="chosenTheme" :box-style="true" v-model="site.colors.buttonBackground" :key="newTime"/>
                </div>
            </div>

            <div class="dF aC" style="gap:2ch;">
                <div class="f1">Button Text Color</div>
                <div>
                    <ColorPicker :defaults="chosenTheme" :box-style="true" v-model="site.colors.buttonText" :key="newTime"/>
                </div>
            </div> -->
            
           
        </div>
        <div class="dF jSB title-second-color-picker aC" style="salign-items:baseline"  :style="useThemeDefaults ? `opacity:0.5; pointer-events:none;` : ``">
            <h4 class="mb-0">Suggested Color Schemes</h4>
            <a href="#" @click.prevent="generateColor" class="hover:text-primary text-sm" style="text-decoration:underline">Randomize Suggestion</a>
        </div>

        <div class="colr-grid color-scheme-choices"  :style="useThemeDefaults ? `opacity:0.5; pointer-events:none;` : ``">
            <div class="scheme-choice" v-for="(item,itemI) in colorSuggestions" :key="itemI + 'color-choices'" @click="chooseColor(itemI)" :class="chosenSuggestion === itemI ? 'selected-color-suggestion' : ''">
                <span v-for="(colorItem,idx) in item" :key="idx + 'colorItem'" :style="`--color-here:${colorItem}`"></span>
            </div>
        </div>
        <portal to="destination">
            <a-button size="large" class="back-button" @click="next">Next</a-button>
        </portal>
       
    </div>
</template>

<script>

    import {getAppIcon,validateEmail,setProp} from 'bh-mod'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import ColorPicker from '@/components/site/ColorPicker'
    
    var colorChoices = require('nice-color-palettes');

    export default {
        props:{
            value:Number,
			template:Object,
        },
        components:{
            ImageBoxSelector,ColorPicker,
        },
        data:() => ({
			useThemeDefaults:false,
            newTime:Date.now(),
            colorSuggestions:[],
            chosenSuggestion:-1,
            chosenTheme:[],
            site:{
                colors:{
                    primaryColor:'#cccccc',
                    secondaryColor:'#aaaaaa',
                    textColor:'#aaaaaa',
                    accentColor:'#aaaaaa',
                    buttonBackground:'#aaaaaa',
                    buttonText:'#aaaaaa',

                }
            },
            currentStep:0,

            rules: {
                name: [
                    { required: true, message: 'Please input a broadcast name', trigger: 'blur' }
                ],
                darkLogo: [
                    { required: true, message: 'Please provide your dark logo' }
                ],
                lightLogo: [
                    { required: true, message: 'Please provide your light logo' }
                ],
            },
            onBoarding:false,
        }),
        watch:{
            currentStep(val){
                return this.$emit('input',val)
            }
        },
        computed:{
            instance(){
            return this.$store.state.instance
            },
            theApp(){
            return this.$store.state.theApp
            },
        
        },
        methods:{
            chooseColor(index){

                this.chosenSuggestion = index

                let colors = this.colorSuggestions[index]
                this.chosenTheme = JSON.parse(JSON.stringify(colors))

                let keys = ['secondaryColor','primaryColor','textColor','accentColor','buttonBackground']
                keys.forEach((key,idx) => {
                    setProp(this, ['site','colors',key], colors[idx])
                })
                
                setProp(this, ['site','colors','buttonText'], '#FFFFFF')
                this.newTime = Date.now()

            },
            skip(){
                return this.$emit('next')
            },
            next(){
				if (this.useThemeDefaults){
					return this.$emit('done', {colors:this.template._global.colors})
				} else {
					let sendOBJ = {colors:{...this.site.colors,_theme:this.chosenTheme}}
					return this.$emit('done', sendOBJ)
				}
            },
            generateColor(){
                let ele = document.querySelector('.scroll-child');
                console.log('SCROLL ELEMENT', ele)
                ele.scrollTop = ele.scrollHeight
                this.chosenSuggestion = -1
                let theColors = []
                for (let i = 0; i < 6;i++){
                    let index = Math.ceil(Math.random() * 99)
                    while(theColors.includes(index)) index = Math.ceil(Math.random() * 99)
                    // console.log('index',index)
                    // console.log('choices', colorChoices[index])
                    theColors.push(index)
                }
                this.colorSuggestions = theColors.map(idx => colorChoices[idx])
            }

        },
        created(){
            this.generateColor()
            this.chooseColor(0)
			console.log('THIS TEMPLATE', this.template)
			let {_global = {}} = this.template
			if (_global.colors){
				Object.entries(_global.colors).forEach( ([key,value]) => {

					if (typeof value === 'string' && value.includes('#') && value.length === 7) this.site.colors[key] = value

				})
			}
			if (_global.colors.custom) this.site.colors.custom = _global.colors.custom
        }
    }
</script>

<style lang="scss" >
    .colr-grid{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
        gap:1.5ch;
        > div {
            max-width: 300px;
        }
        @media screen and (min-width:1200px){
            gap:3.5ch;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .title-second-color-picker{
        margin-top:30px;
        margin-bottom:3rem;
        @media screen and (min-width:1200px){
            margin-top:70px;
        }
    }
    .scheme-choice{
        display:flex;
        cursor:pointer;
        padding:5px;
        border:1px solid transparent;
        &.selected-color-suggestion{
            border:1px solid var(--secondary);
        }
        span{
            height:15px;
            flex:1;
            background:var(--color-here);
        }
    }
</style>