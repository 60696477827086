<template>
  <div>
        <div class="preview_template_modal" :class="template? 'show' : ''">
            <div class="preview_template_modal-wrapper">
                
                <div class="preview_template_modal-header dF aC py-2 px-4" style="gap:1ch">
                    <a-icon class="back-button" type="arrow-left" @click.stop="$emit('close')" /><h6 class="mb-0">Previewing: {{template.name}}</h6>
                </div>
                <div class="preview_template_modal-body">
                    <iframe :src="`${$store.state.appData.siteURL}/__set/${template}`" class="template-preview-iframe" />
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:['template'],
    data:() => ({
    })

}
</script>

<style lang="scss" scoped>
    .back-button{
        cursor:pointer;
        transition:transform .2s ease-out;
        &:hover{
            transform:translate3d(-3px,0,0);
            color:var(--primary);
        }
    }
    .preview_template_modal{
        position: absolute;
        left:0;
        top:0;
        opacity:0;
        pointer-events: none;

        &.show{
            opacity:1;
            pointer-events: all;
        }
        .preview_template_modal-wrapper{
            z-index:301;
            position: fixed;
            left:0;
            width:100vw;
            top:0;
            height:100vh;
            background:white;
            display:flex;
            flex-direction: column;
            min-height:0;
        }
        .preview_template_modal-body{
            flex:1;
            overflow: auto;
            min-height:0;
            padding:0 2.5rem;
        }
        .template-preview-iframe{
            height:100%;
        }
    }
</style>